import React, { useEffect, useRef, useState } from "react";
import "../../../default_styles/tb-colors.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as DefaultColors from "../../../default_values/DefaultColors";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import moment from "moment";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import "moment/locale/de";
import "moment/locale/fr";
import TeambookColorPicker from "./../../../default_components/TeambookColorPicker";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ClientCreationDialog } from "../Client/ClientCreationDialog";
import { managerOptions } from "../lib/managerOptions";
import { statusOptions } from "../lib/statusOptions";
import { dateFormat } from "../../../default_values/defaultDateFormats";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { DateTime } from "luxon";
import { useDataChange } from "../../../../lib/ussDataChange";
import WarningModal from "../../../default_components/WarningModal";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import ProjectAdvancedTab from "../default_components/ProjectAdvancedTab";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import MuiDatePicker from "../../../default_components/MuiDatePicker";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import { useNotificationStore } from "../../../../stores/notificationStore";
import TeambookOptionsLine from "../../../default_components/TeambookOptionsLine";
import { defaultProjectIcons, projectIconsQuantity } from "../../../default_values/DefaultProjectIcons";

const findFirstFreeColor = (projects) => {
  let availableColors = [...DefaultColors.projectColors];

  projects.forEach((project) => {
    let indexOfElement = availableColors.indexOf(project.color);

    if (indexOfElement !== -1) {
      availableColors.splice(indexOfElement, 1);
    }
  });

  if (availableColors.length > 0) {
    return availableColors[0];
  } else {
    return DefaultColors.projectColors[0];
  }
};

const CreateProject = ({
  clients,
  closeCreation,
  projects,
  getActiveProjects,
  page,
  setAllClients,
  users,
  tab,
  setTab,
}) => {
  const { t } = useTranslation();

  const kindOptions = [
    { value: "billable", name: t("projects.billable") },
    { value: "non_billable", name: t("projects.non_billable") },
  ];

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [client, setClient] = useState(null);
  const [kind, setKind] = useState(kindOptions[0]);
  const [estimated, setEstimated] = useState(null);
  const [estimatedManDays, setEstimatedManDays] = useState();
  const [color, setColor] = useState(findFirstFreeColor(projects));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [notes, setNotes] = useState("<p></p>");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [defineDates, setDefineDates] = useState({
    startDate: false,
    endDate: false,
  });
  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const [clientCreationDelaogOpened, setClientCreationDelaogOpened] = useState(false);
  const [manager, setManager] = useState(null);
  const [status, setStatus] = useState(null);
  const [businessUnit, setBusinessUnit] = useState(null);
  const editorRef = useRef();
  const [warningOpened, setWarningOpened] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const [iconId, setIconId] = useState(1);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    if (account) {
      moment.updateLocale("en", {
        week: {
          dow: account.first_week_day === "Monday" ? 1 : 0,
        },
      });
    }
  }, [account]);

  const isChanged = useDataChange(
    {
      name,
      code,
      client_id: client?.id || null,
      kind: kind,
      start_date: startDate,
      end_date: endDate,
      manager_id: manager?.value || null,
      status: status?.value || null,
      business_unit: businessUnit || null,
      estimated: estimated || null,
      color: color,
      notes: notes,
    },
    {
      name: "",
      code: "",
      client_id: null,
      kind: kindOptions[0],
      start_date: null,
      end_date: null,
      manager_id: null,
      status: null,
      business_unit: null,
      estimated: null,
      color: findFirstFreeColor(projects),
      notes: "<p></p>",
    }
  );

  const createClient = (name) => {
    Api.Clients.create({
      name: name,
      active: true,
      phone_number: "",
      email: "",
      notes: "",
    }).then((response) => {
      setAllClients([...clients, response.data]);
      setClient(response.data);
    });
  };

  const anchorRef = React.useRef(null);

  const closeWarningModal = () => {
    setWarningOpened(false);
  };

  const submitForm = () => {
    if (endDate && startDate > endDate) {
      setErrorMessage("Start Date can't be after End Date");
      return;
    }

    Api.Projects.create({
      name: name,
      code: code,
      client_id: client?.id || null,
      kind: page === "create" ? kind.value : "time_off",
      estimated: estimated,
      color: page === "create" ? color : "#7a7a7a",
      icon_id: iconId,
      start_date: startDate,
      end_date: endDate,
      manager_id: manager?.value,
      status: status?.value,
      business_unit: businessUnit,
      notes: editorRef?.current?.getComment() === "<p></p>" ? null : editorRef?.current?.getComment(),
      active: true,
      tasks: selectedTasks.map((t) => t.value),
      custom_fields: JSON.stringify(customFields),
    })
      .then((response) => {
        analyzeResponse(response.data);
      })
      .catch((error) => {
        analyzeNewProjectError(error);
        setTab(projectTabs[0].value);
      });
  };

  const analyzeResponse = (project) => {
    getActiveProjects(project);
    closeCreateWindow(false);
    setInAppNotification(t("projects.created_successfully", { name: name, code: code }));
  };

  const analyzeNewProjectError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
    closeWarningModal();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const closeCreateWindow = (handleChanges = true) => {
    if (isChanged && handleChanges) {
      setWarningOpened(true);
    } else {
      closeCreation();
      setTab(projectTabs[0].value);
    }
  };

  const changeDefineDatesState = (event, field) => {
    let newState = !defineDates[field];

    if (newState) {
      if (field === "startDate") {
        setStartDate((pr) => DateTime.now().plus({ month: 1 }).startOf("month").startOf("day").plus({ hours: 12 }));
      } else {
        setEndDate(DateTime.now().plus({ month: 1 }).endOf("month").startOf("day").plus({ hours: 12 }));
      }
    } else {
      if (field === "startDate") {
        setStartDate(null);
      } else {
        setEndDate(null);
      }
    }
    let newDefinedState = { ...defineDates };

    newDefinedState[field] = !defineDates[field];

    setDefineDates(newDefinedState);
  };

  const projectTabs = [
    {
      value: "general",
      text: t("users.general_info"),
      icon: <TeambookIcon name={icons.PROJECT} color={tab === "general" ? "alternative_default" : "default"} />,
      onClick: () => setTab("general"),
    },
    {
      value: "tasks",
      text: t("planning.filter.advanced"),
      icon: <TeambookIcon name={icons.ADVANCED} color={tab === "tasks" ? "alternative_default" : "default"} />,
      onClick: () => setTab("tasks"),
    },
  ];

  let timeOffIcons = [];

  for (let i = 1; i < projectIconsQuantity; i++) {
    timeOffIcons.push(
      <div
        className="time-off__icon"
        onClick={() => setIconId(i)}
        style={{ border: i === iconId ? "1px solid var(--stroke)" : "1px solid transparent" }}
      >
        {defaultProjectIcons(i, "var(--text-10)")}
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={closeCreateWindow}
      className={`form-create projects-form`}
      project-type={page === "time_off_create" && "time_off"}
    >
      <DialogTitle>
        <p>{page === "create" ? t("projects.create_project") : t("settings.time_off.create_time_off")}</p>
        <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeCreateWindow} />
      </DialogTitle>

      <DialogContent
        style={{
          flexDirection: "column",
          padding: "0px 40px 0px 40px",
        }}
      >
        {page === "create" && (
          <div style={{ width: "100%" }} className="flex users-data__tabs">
            <TeambookOptionsLine options={projectTabs} selectedOption={tab} />
          </div>
        )}

        {tab === "general" ? (
          <div style={{ display: "flex", gap: 20 }}>
            <div className="project-form__left-side">
              {page === "create" ? (
                <>
                  <WhiteTooltip title={t("projects.change_color")}>
                    <div
                      className="project-form__project-color"
                      style={{ backgroundColor: color, cursor: "pointer" }}
                      onClick={() => setChangeColorsOpened(true)}
                      ref={anchorRef}
                    />
                  </WhiteTooltip>

                  <TeambookColorPicker
                    color={color}
                    setColor={setColor}
                    changeColorsOpened={changeColorsOpened}
                    setChangeColorsOpened={setChangeColorsOpened}
                    anchorRef={anchorRef}
                  />

                  <TeambookColorPicker
                    color={color}
                    setColor={setColor}
                    changeColorsOpened={changeColorsOpened}
                    setChangeColorsOpened={setChangeColorsOpened}
                    anchorRef={anchorRef}
                  />
                </>
              ) : (
                <div
                  className="project-form__project-color timeoff"
                  onClick={() => setChangeColorsOpened(true)}
                  ref={anchorRef}
                >
                  {React.cloneElement(defaultProjectIcons(iconId, "unset"), {
                    style: { width: "50px", height: "50px", fill: "none", stroke: "var(--text-10)" },
                  })}
                </div>
              )}
            </div>

            <div className="project-form__right-side">
              <div className="project-form__row">
                <div className="project-form__field">
                  <p className="project-form__field-name">{`${t("projects.name")} *`}</p>

                  <div className="project-form__field-value">
                    <TeambookTextForm
                      id="projectName"
                      className="user-form__field-input"
                      fieldValue={name}
                      onChange={(text) =>
                        setName(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")
                      }
                    />
                  </div>
                </div>

                <div className="project-form__field">
                  <p className="project-form__field-name">{`${t("projects.short_name")} *`}</p>

                  <div className="project-form__field-value">
                    <TeambookTextForm
                      id="projectShortName"
                      className="user-form__field-input"
                      fieldValue={code}
                      onChange={(text) =>
                        setCode(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")
                      }
                    />
                  </div>
                </div>
              </div>

              {page === "time_off_create" && <div className="time-off_icon-block">{timeOffIcons}</div>}

              {errorMessage !== null && (errorField === "name" || errorField === "code") ? (
                <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
              ) : null}

              {page === "create" && (
                <>
                  <div className="project-form__row">
                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.client")}</p>

                      <div className="project-form__field-value">
                        <TeambookReactSelect
                          menuPortalTarget={document.body}
                          id="tags-outlined"
                          isValidNewOption={(option) =>
                            option.length >= 2 &&
                            option.length <= 30 &&
                            !clients.find(({ name }) => name.toLowerCase() === option.toLowerCase().replace(/\s+/g, ""))
                          }
                          options={clients}
                          getOptionLabel={(option) =>
                            option.name !== undefined ? `${option.name}` : `${option.label}`
                          }
                          getOptionValue={({ id }) => id}
                          value={client}
                          defaultValue={client}
                          filterSelectedOptions
                          onCreateOption={createClient}
                          onChange={(client) => setClient(client)}
                          isSearchable={true}
                          formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                          grayPlaceholder
                        />
                      </div>
                    </div>

                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.project_kind")}</p>

                      <div className="project-form__field-value">
                        <TeambookReactSelect
                          id="selectBillable"
                          options={kindOptions}
                          onChange={(new_kind) => setKind(new_kind)}
                          value={kind}
                          height={43}
                          grayPlaceholder
                        />
                      </div>
                    </div>
                  </div>

                  <div className="project-form__row">
                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.estimated")}</p>

                      <div className="project-form__field-value flex">
                        <TeambookTextForm
                          className="user-form__field-input estimated-hours"
                          fieldValue={estimated || ""}
                          onChange={(text) => {
                            setEstimated(text);
                            setEstimatedManDays(Math.round(text / 8));
                          }}
                          placeholder={800}
                          type={"number"}
                        />

                        <p className="estimated-hours__text">h</p>

                        <TeambookTextForm
                          className="user-form__field-input estimated-hours"
                          fieldValue={estimatedManDays || ""}
                          onChange={(text) => {
                            setEstimatedManDays(text);
                            setEstimated(text * 8);
                          }}
                          placeholder={100}
                          type={"number"}
                        />

                        <p className="estimated-hours__text">md</p>
                      </div>
                    </div>

                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.manager")}</p>

                      <div className="project-form__field-value">
                        <TeambookReactSelect
                          options={managerOptions(users)}
                          onChange={(user) => setManager(user)}
                          value={manager}
                          height={43}
                          isSearchable={true}
                          grayPlaceholder
                        />
                      </div>
                    </div>
                  </div>

                  <div className="project-form__row">
                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.status")}</p>

                      <div className="project-form__field-value">
                        <TeambookReactSelect
                          options={statusOptions(t)}
                          onChange={(status) => setStatus(status)}
                          value={status}
                          getOptionLabel={({ name }) => name}
                          height={43}
                          grayPlaceholder
                        />
                      </div>
                    </div>

                    <div className="project-form__field">
                      <p className="project-form__field-name">{t("projects.business_unit")}</p>

                      <div className="project-form__field-value">
                        <TeambookTextForm
                          className="user-form__field-input"
                          fieldValue={businessUnit || ""}
                          onChange={(text) => setBusinessUnit(text)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="project-form__row project-dates">
                    <div className="project-form__field">
                      <TeambookCheckbox
                        className="tb-checkbox"
                        checked={defineDates.startDate}
                        onChange={(event) => changeDefineDatesState(event, "startDate")}
                        color="primary"
                      />

                      <p
                        className="project-form__field-name"
                        onClick={(event) => changeDefineDatesState(event, "startDate")}
                        style={{ cursor: "pointer" }}
                      >
                        {t("projects.start_date")}
                      </p>

                      {defineDates.startDate && (
                        <div className="project-form__field-value">
                          <MuiDatePicker
                            format={"d/MMM/yyyy"}
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            sx={{ width: "148px" }}
                            className="mui-date-picker"
                            localeText={{
                              fieldDayPlaceholder: () => "dd",
                              fieldMonthPlaceholder: () => "mm",
                              fieldYearPlaceholder: () => "yyyy",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="project-form__field">
                      <TeambookCheckbox
                        className="tb-checkbox"
                        checked={defineDates.endDate}
                        onChange={(event) => changeDefineDatesState(event, "endDate")}
                        color="primary"
                      />

                      <p
                        className="project-form__field-name"
                        onClick={(event) => changeDefineDatesState(event, "endDate")}
                        style={{ cursor: "pointer" }}
                      >
                        {t("projects.end_date")}
                      </p>

                      {defineDates.endDate && (
                        <p className="project-form__field-value">
                          <MuiDatePicker
                            format={"d/MMM/yyyy"}
                            value={endDate}
                            minDate={startDate || undefined}
                            onChange={(date) => setEndDate(date)}
                            onError={(e) => {
                              if (e?.length) {
                                setErrorField("end_date");
                                setErrorMessage(e);
                              }
                            }}
                            sx={{ width: "148px" }}
                            className="mui-date-picker"
                            localeText={{
                              fieldDayPlaceholder: () => "dd",
                              fieldMonthPlaceholder: () => "mm",
                              fieldYearPlaceholder: () => "yyyy",
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </div>

                  {errorMessage && errorField === "end_date" ? (
                    <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
                  ) : null}

                  <div className="project-form__row">
                    <div className="project-form__full-row-value">
                      <div className="project-form__field-value">
                        <RichTextEditor onChange={setNotes} ref={editorRef} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <ProjectAdvancedTab
            tasks={selectedTasks}
            setTasks={setSelectedTasks}
            customFields={customFields}
            setCustomFields={setCustomFields}
          />
        )}

        <ClientCreationDialog
          open={clientCreationDelaogOpened}
          setOpen={setClientCreationDelaogOpened}
          clients={clients}
          setAllClients={setAllClients}
          setClient={setClient}
        />
      </DialogContent>

      <DialogActions style={{ padding: "0px 40px 40px 40px" }}>
        <TeambookBlueButton id="createProject" onClick={submitForm} className="form__edit-button" text={t("create")} />
      </DialogActions>

      {warningOpened && (
        <WarningModal
          onNo={() => {
            closeCreation();
          }}
          onYes={submitForm}
          closeModal={closeWarningModal}
        />
      )}
    </Dialog>
  );
};

export default CreateProject;
